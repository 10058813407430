import axios from 'axios';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Redirect } from 'react-router';
import { config } from '../config';
import { useAuth } from '../providers/authProvider';

const Login = () => {
  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [invalidPass, setInvalidPass] = useState(false);

  const validateForm = () => user.length > 0 && pass.length > 0;

  const handleSubmit = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    axios
      .post(config.endpoints.login, { user, password: pass })
      .then((response) => {
        if (response.data && response.data.token) {
          setAuth(response.data);
        } else {
          setInvalidPass(true);
        }
      })
      .catch(function (error) {
        setInvalidPass(true);
      });
  };

  return auth.token ? (
    <Redirect to="/index" />
  ) : (
    <Container style={styles.container}>
      {invalidPass && (
        <Form.Text style={styles.alert}>Usuario y/o clave incorrecta</Form.Text>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>User</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        </Form.Group>
        <br />
        <Button
          styles={styles.button}
          block
          size="lg"
          type="submit"
          disabled={!validateForm()}
        >
          Login
        </Button>
      </Form>
    </Container>
  );
};

const styles = {
  container: {
    width: '500px',
    margin: '200px auto',
    backgroundColor: '#F4F4FF',
    padding: '30px',
    borderRadius: '15px',
  },
  alert: {
    backgroundColor: 'brown',
    color: 'white',
    padding: '0.5em',
    marginBottom: '1em',
    display: 'block',
    fontSize: '1.1em',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

export default Login;
