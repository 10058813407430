import axios from "axios";
import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { Redirect } from "react-router";
import * as XLSX from "xlsx";
import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { config } from "../config";
import { useAuth } from "../providers/authProvider";
import Pusher from "pusher-js";

const pusher = new Pusher("1a77cd7b923190c86de0", {
  cluster: "us2",
});

const { ExportCSVButton } = CSVExport;
const ERROR = { hasError: false, message: "" };
const columns = [
  {
    dataField: "IDCedula",
    text: "IDCedula",
  },
  {
    dataField: "IDNombre",
    text: "IDNombre",
  },
  {
    dataField: "IDCelular",
    text: "IDCelular",
  },
  {
    dataField: "IDTel",
    text: "IDTel",
  },
  {
    dataField: "IDTel2",
    text: "IDTel2",
  },
  {
    dataField: "IDTel3",
    text: "IDTel3",
  },
];

const CargaDatosNuevoSiglo = (props) => {
  const [auth] = useAuth();
  const [redirect, setRedirect] = useState();

  const [items, setItems] = useState([]);
  const [loadReports, setLoadReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(ERROR);
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState();

  const channel = pusher.subscribe("ns");
  channel.bind("load", function (data) {
    console.log(data);
    setProgress(data.message);
  });

  const handleError = (error) => {
    setItems([]);
    setLoading(false);
    setError({ hasError: true, message: error.message });
    setProgress("");
  };

  const handleSuccess = (response) => {
    toast.info("Base cargada correctamente", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    setLoading(false);
    setLoadReports(response?.data?.uniqueIdNotFound);
    setProgress("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadReports([]);
    const data = [fileName, auth.user, items];
    axios
      .post(config.endpoints.cargaNuevoSiglo, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
        Accept: "application/json",
        "Content-Type": "application/json",
      })
      .then(handleSuccess)
      .catch(handleError);
  };

  const readExcel = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      /* Parse data */
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: 'buffer' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      
      setItems(data);
      setLoading(false);
    };

    fileReader.onerror = handleError;
  };

  if (!auth.token) {
    return <Redirect to="/" />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Container>
      <AppNavbar />
      <Card className="mt-3">
        <Card.Header>Carga Ventas - Nuevo Siglo</Card.Header>
        <Card.Body>
          {error.hasError && (
            <div class="alert alert-danger" role="alert">
              <h5 class="alert-heading">Error procesando el archivo</h5>
              <hr />
              <p>{error.message}</p>
            </div>
          )}

          {items.length === 0 && (
            <Form>
              <Row>
                <Col sm="4">
                  <Form.Label>Seleccione un archivo:</Form.Label>
                </Col>
                <Col sm="8">
                  <Form.File id="formcheck-api-regular">
                    <Form.File.Input
                      type="file"
                      onChange={(e) => {
                        setError(ERROR);
                        setLoading(true);
                        const file = e.target.files[0];
                        setFileName(file.name);
                        readExcel(file);
                      }}
                    />
                  </Form.File>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="12">
                  <strong>Nota:</strong> Únicamente se permite cargar archivos
                  de Excel en formato .xlsx .xls .ods
                </Col>
              </Row>
            </Form>
          )}

          {items.length !== 0 && (
            <Tabs defaultActiveKey="resume">
              <Tab eventKey="resume" title="Resumen" tabClassName="mb-3">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mt-3">
                    <Form.Label>Archivo</Form.Label>
                    <Form.Control
                      type="text"
                      value={fileName}
                      disabled
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label>Total Items</Form.Label>
                    <Form.Control
                      type="text"
                      value={items.length}
                      disabled
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mt-3 text-end">
                    <Row className="mt-3">
                      <Col sm="1">
                        {loading && (
                          <Spinner animation="border" role="status" />
                        )}
                      </Col>
                      <Col sm="6" className="text-start">
                        {loading && progress}
                      </Col>
                      <Col sm="5" className="text-end">
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() => setRedirect("/index")}
                        >
                          Cancelar
                        </Button>{" "}
                        <Button variant="primary" type="submit">
                          Cargar Archivo
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Tab>
              <Tab eventKey="details" title="Detalles" tabClassName="mb-3">
                <BootstrapTable
                  striped
                  bordered
                  hover
                  keyField={Object.entries(items[0])[0][0]}
                  data={items}
                  columns={Object.entries(items[0]).map((item) => ({
                    dataField: item[0],
                    text: item[0],
                  }))}
                  pagination={paginationFactory()}
                />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </Card>
      {loadReports?.length > 0 && (
        <Card className="mt-3">
          <Card.Header>Reporte de carga</Card.Header>
          <Card.Body>
            <ToolkitProvider
              keyField="IDCedula"
              data={loadReports}
              columns={columns}
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col sm="10">
                      <h4>Registros sin llamdas asociadas</h4>
                    </Col>
                    <Col sm="2" className="text-end">
                      <ExportCSVButton { ...props.csvProps } className="btn-primary">
                        Export CSV!!
                      </ExportCSVButton>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <BootstrapTable
                      striped
                      bordered
                      hover
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </Row>
                </>
              )}
            </ToolkitProvider>
          </Card.Body>
        </Card>
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </Container>
  );
};

export default CargaDatosNuevoSiglo;
