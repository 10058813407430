import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Table,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap';
import { Redirect } from 'react-router';
import AppNavbar from '../components/AppNavbar';
import { config } from '../config';
import { useAuth } from '../providers/authProvider';

const Clients = () => {
  const [auth] = useAuth();
  const [clients, setClients] = useState([]);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [client, setClient] = useState({
    client: '',
    user: '',
    password: '',
  });

  const handleClose = () => {
    setClient({
      client: '',
      user: '',
      password: '',
    });
    setShow(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    await axios.post(config.endpoints.clients, client, {
      headers: { Authorization: `Bearer ${auth.token}` },
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    setLoading(false);
    handleClose();
    setReload(!reload);
  };

  // Load clients
  useEffect(() => {
    const getClients = async () => {
      const response = await axios.get(config.endpoints.clients, {
        headers: { Authorization: `Bearer ${auth.token}` },
        Accept: 'application/json',
        'Content-Type': 'application/json',
      });
      setClients(response.data);
    };
    getClients();
  }, [auth.token, auth.user, reload]);

  if (!auth.token) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <AppNavbar />
      <Container>
        <div style={styles.title}>
          <h1>Clientes</h1>
          <div className="align-middle">
            <Button className="mt-2" onClick={() => setShow(true)}>
              Nuevo Cliente
            </Button>
          </div>
        </div>
        <hr />
        <Table striped responsive bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Cliente</th>
              <th>Habilitado</th>
            </tr>
          </thead>
          <tbody className="table-striped">
            {clients.map((client) => (
              <tr key={client.id}>
                <th>{client.id}</th>
                <td>{client.usuario}</td>
                <td>{client.tipoCliente}</td>
                <td>{client.habilitado === 1? 'SI' : 'NO'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Form>
          <Modal.Header>
            <Modal.Title>Nuevo cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading && (
              <div className="text-center">
                <Spinner animation="border" variant="warning" />
              </div>
            )}
            {!loading && (
              <>
                <Form.Group controlId="client">
                  <Form.Label>Cliente</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Cliente"
                    onChange={(e) => {
                      setClient({
                        ...client,
                        client: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="user">
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Usuario"
                    onChange={(e) => {
                      setClient({
                        ...client,
                        user: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setClient({
                        ...client,
                        password: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!loading && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Cerrar
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                  Guardar
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

const styles = {
  title: {
    margin: '1em 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default Clients;
