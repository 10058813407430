import { useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Redirect, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '../providers/authProvider';

const ADMIN = 'admin';
const LAGOMAR = 'lagomar';
const NUEVOSIGLO = 'nuevosiglo';

const AppNavbar = ({ history }) => {
  const [auth, setAuth] = useAuth();
  const [redirect, setRedirect] = useState();

  const onClose = () => {
    setAuth({ token: null, role: null, user: null });
    setRedirect("/");
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Navbar bg="light">
      <Navbar.Brand href="#">
        <Link to="/">
          <img
            alt="Rua Asistencia"
            src="/logo.png"
            width="211"
            height="67"
            className="d-inline-block align-top"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-start">
        <Nav className="mr-auto">
          {auth.role === ADMIN && (
            <Link to="/clients" className="nav-link">
              Clientes
            </Link>
          )}
          <Link to="/" className="nav-link">
            Dashboard
          </Link>
            <NavDropdown title="Carga" id="basic-nav-dropdown">
              {(auth.role === ADMIN || auth.role === LAGOMAR) && (
                <NavDropdown.Item
                  href="#"
                  onClick={() => setRedirect('/lagomar')}
                >
                  Lagomar
                </NavDropdown.Item>
              )}
              {(auth.role === ADMIN || auth.role === NUEVOSIGLO) && (
                <NavDropdown.Item
                  href="#"
                  onClick={() => setRedirect('/nuevosiglo')}
                >
                  Nuevo Siglo
                </NavDropdown.Item>
              )}

              {(auth.role === ADMIN || (auth.role !== NUEVOSIGLO && auth.role !== LAGOMAR)) && (
                <NavDropdown.Item
                href="#"
                onClick={() => setRedirect('/genericLoad')}
              >
                Carga padron completo
              </NavDropdown.Item>
              )}
              {(<NavDropdown.Item
                href="#"
                onClick={() => setRedirect('/incrementalUpload')}
              >
                Carga padron incremental
              </NavDropdown.Item>              
              )}
          </NavDropdown>
          

        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Nav className="mr-auto">
          <NavDropdown title={auth.user + ' '} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={onClose}>Salir</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withRouter(AppNavbar);
