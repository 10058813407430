const activeEnv = 'prod';

const environments = {
  dev: {
    API_BASE: 'http://localhost:8080',
  },
  prod: {
    API_BASE: 'https://api.rua-asistencia.com',
  },
};

const env = environments[activeEnv];

export const config = {
  endpoints: {
    login: `${env.API_BASE}/v2/auth/signin`,
    clients: `${env.API_BASE}/v2/clients`,
    logs: `${env.API_BASE}/v2/logs`,
    cargaNuevoSiglo: `${env.API_BASE}/NuevoSiglo/CargaVentas`,
    cargaLagomar: `${env.API_BASE}/Lagomar/Carga`,
    genericLoad: `${env.API_BASE}/generic/Load`,
    incrementalUpload: `${env.API_BASE}/generic/incrementalUpload`,

  },
};
