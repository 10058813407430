import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import AppNavbar from '../components/AppNavbar';
import { config } from '../config';
import { useAuth } from '../providers/authProvider';

const ADMIN = 'admin';

const Dashboard = () => {
  const [auth] = useAuth();
  const [data, setData] = useState([]);

  // Load dashboard data
  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        config.endpoints.logs,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      );
      setData(response.data);
    };
    getData();
  }, [auth.token, auth.user]);

  if (!auth.token) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <AppNavbar />
      <Container>
        <h1 style={styles.title}>Registros cargados</h1>
        <Table striped responsive bordered hover>
          <thead>
            <tr>
              <th colSpan="1">Fecha de carga</th>
              <th colSpan="2">Nombre del documento</th>
              <th colSpan="2">Ventas cargadas</th>
              <th colSpan="2">No Ventas cargadas</th>
              <th colSpan="2">Errores</th>
              {auth.role === ADMIN && <th colSpan="2">Usuario</th>}
            </tr>
          </thead>
          <tbody className="table-striped">
            {data.map((item) => (
              <tr key={item.fecha}>
                <th colSpan="1">{item.fecha}</th>
                <td colSpan="2">{item.nombre}</td>
                <td colSpan="2">{item.ventasOk}</td>
                <td colSpan="2">{item.noVentas}</td>
                <td colSpan="2">{item.errores}</td>
                {auth.role === ADMIN && <td colSpan="2">{item.usuario}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Container>
  );
};

const styles = {
  title: {
    margin: '1em 0',
  },
};

export default Dashboard;
