import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import CargaDatos from './cargas/cargaDatos';
import CargaDatosNuevoSiglo from './cargas/cargaNS';
import GenericLoad from './cargas/genericLoad';
import IncrementalUpload from './cargas/incrementalUpload';

import Clients from './pages/clients';
import Home from './pages/home';
import Login from './pages/login';
import { AuthProvider } from './providers/authProvider';

const App = () => (
  <AuthProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/index" component={Home} />
        <Route path="/lagomar" component={CargaDatos} />
        <Route path="/nuevosiglo" component={CargaDatosNuevoSiglo} />
        <Route path="/clients" exact component={Clients} />
        <Route path="/genericLoad" component={GenericLoad} />
        <Route path="/incrementalUpload" component={IncrementalUpload} />
      </Switch>
    </BrowserRouter>
  </AuthProvider>
);

export default App;
