import axios from 'axios';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Redirect } from 'react-router';
import * as XLSX from 'xlsx';
import '../App.css';
import AppNavbar from '../components/AppNavbar';
import { config } from '../config';
import { useAuth } from '../providers/authProvider';

const CargaDatosLagomar = (props) => {
  const [auth] = useAuth();
  const [redirect, setRedirect] = useState();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [fileName, setFileName] = useState('');

  const handleError = (error) => {
    console.log(error);
    setItems([]);
    setLoading(false);
    setError(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = [fileName, auth.user, items];

    axios
      .post(config.endpoints.cargaLagomar, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
      .then(function (response) {
        console.log(response);
        setRedirect('/index');
      })
      .catch(handleError);
  };

  const readExcel = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      /* Parse data */
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: 'buffer' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      setItems(data);
      setLoading(false);
    };

    fileReader.onerror = handleError;
  };

  if (!auth.token) {
    return <Redirect to="/" />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Container>
      <AppNavbar />
      <Card className="mt-3">
        <Card.Header>Carga Ventas - Lagomar</Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              <Alert.Heading>Error procesando archivo</Alert.Heading>
              <Alert.Body>
                Verifique el archivo cargado, recuerde que solo únicamente se
                permite cargar archivos de Excel en formato .xlsx .xls .ods
              </Alert.Body>
            </Alert>
          )}
          {items.length === 0 && (
            <Form>
              <Row>
                <Col sm="4">
                  <Form.Label>Seleccione un archivo:</Form.Label>
                </Col>
                <Col sm="8">
                  <Form.File id="formcheck-api-regular">
                    <Form.File.Input
                      type="file"
                      onChange={(e) => {
                        setLoading(true);
                        const file = e.target.files[0];
                        setFileName(file.name);
                        readExcel(file);
                      }}
                    />
                  </Form.File>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="12">
                  <strong>Nota:</strong> Únicamente se permite cargar archivos
                  de Excel en formato .xlsx .xls .ods
                </Col>
              </Row>
            </Form>
          )}
          {items.length !== 0 && (
            <Tabs defaultActiveKey="resume">
              <Tab eventKey="resume" title="Resumen" tabClassName="mb-3">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mt-3">
                    <Form.Label>Archivo</Form.Label>
                    <Form.Control
                      type="text"
                      value={fileName}
                      disabled
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label>Total Items</Form.Label>
                    <Form.Control
                      type="text"
                      value={items.length}
                      disabled
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mt-5 text-end">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => setRedirect('/index')}
                    >
                      Cancelar
                    </Button>{' '}
                    <Button variant="primary" type="submit">
                      Cargar Archivo
                    </Button>
                  </Form.Group>
                </Form>
              </Tab>
              <Tab eventKey="details" title="Detalles" tabClassName="mb-3">
                <BootstrapTable
                  striped
                  bordered
                  hover
                  keyField={Object.entries(items[0])[0][0]}
                  data={items}
                  columns={Object.entries(items[0]).map((item) => ({
                    dataField: item[0],
                    text: item[0],
                  }))}
                  pagination={paginationFactory()}
                />
              </Tab>
            </Tabs>
          )}
          {loading && <Spinner animation="border" role="status" />}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CargaDatosLagomar;
